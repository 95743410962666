export const Button = (props) => {
    let passProps = {...props};
    delete passProps['children'];
    return  <a
        className="bg-red-500 !text-white !no-underline hover:bg-red-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        target="_blank"
        {...passProps}
    >
        {props.children}
    </a>
}
