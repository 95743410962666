import "./Home.scss";
import * as style from "./Home.scss.json";
import {Button} from "../components/Button";

export default function Home() {
    return (
        <div className={style.Home}>

            {/*<h1>Fast Sspinning Fietsverhuur</h1>*/}
            <section>
                {/*<h2>*/}
                {/*    Uw partner voor succesvolle Spinning® of indoor cycling events.*/}
                {/*</h2>*/}
                <div className="m-auto text-center lg:w-8/12 xl:w-8/12">
                    <h2 className="text-2xl text-red-900 font-bold md:text-4xl mt-8 md:mt-16">
                        Uw partner voor succesvolle Spinning® of indoor cycling events.
                    </h2>
                </div>

            </section>
            <section>
                {/*<p>*/}
                {/*    Fast Sspinning is één van de grootste verhuuraanbieders van spinningfietsen in heel Nederland.*/}
                {/*</p>*/}
                <h3 className="text-lg md:text-xl text-gray-800 my-8 md:my-16">
                    De volgende <span className="text-red-500">70 fietsen van Schwinn®</span> zijn ter beschikking voor
                    korte of lange termijn verhuur.
                </h3>
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 md:gap-16">
                    <div className="relative z-10 group w-full">
                        <div aria-hidden="true"
                             className="absolute top-0 w-full h-full rounded-2xl bg-white shadow-xl transition duration-500 group-hover:scale-105 lg:group-hover:scale-110"></div>
                        <div className="relative p-6 space-y-6 lg:p-8">
                            <h3 className="text-xl md:text-3xl text-gray-700 font-semibold text-center">Schwinn Evolution</h3>
                            <div>
                                <div className="relative flex justify-around">
                                    <div className={style.image}
                                         style={{backgroundImage: "url(/static/fietsverhuur-20xschwinnevolution.jpg)"}}/>
                                </div>
                            </div>
                            <p className="center text-lg font-semibold text-gray-600">20 fietsen beschikbaar</p>
                        </div>
                    </div>
                    <div className="relative z-10 group w-full">
                        <div aria-hidden="true"
                             className="absolute top-0 w-full h-full rounded-2xl bg-white shadow-xl transition duration-500 group-hover:scale-105 lg:group-hover:scale-110"></div>
                        <div className="relative p-6 space-y-6 lg:p-8">
                            <h3 className="text-xl md:text-3xl text-gray-700 font-semibold text-center">Schwinn IC - PRO</h3>
                            <div>
                                <div className="relative flex justify-around">
                                    <div className={style.image}
                                         style={{backgroundImage: "url(/static/fietsverhuur-70xschwinnicpro.jpg)"}}/>
                                </div>
                            </div>
                            <p className="center text-lg font-semibold text-gray-600">50 fietsen beschikbaar</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div
                    className="mt-12 m-auto -space-y-4 items-center justify-center md:flex md:space-y-0 md:-space-x-4 xl:w-10/12">
                    <div className="relative z-10 -mx-4 group md:w-6/12 md:mx-0 lg:w-5/12">
                        <div aria-hidden="true"
                             className="absolute top-0 w-full h-full rounded-2xl bg-white shadow-xl transition duration-500 group-hover:scale-105 lg:group-hover:scale-110"></div>
                        <div className="relative p-6 space-y-6 lg:p-8">
                            <h3 className="text-3xl text-gray-700 font-semibold text-center">Vanaf</h3>
                            <div>
                                <div className="relative flex justify-around">
                                    <div className="flex items-end">
                                        <span className="text-5xl md:text-8xl text-gray-800 font-bold leading-0">€17,-</span>
                                        {/*<div className="pb-2">*/}
                                        {/*    <span className="block text-2xl text-gray-700 font-bold">per dag</span>*/}
                                        {/*    <span className="block text-xl text-red-500 font-bold">per fiets</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <p className="center font-semibold text-gray-600">Per dag, per fiets</p>
                            {/*<ul role="list" className="w-max space-y-4 py-6 m-auto text-gray-600">*/}
                            {/*    <li className="space-x-2">*/}
                            {/*        /!*<span className="text-red-500 font-semibold">&check;</span>*!/*/}
                            {/*        <span>Per dag, per fiets</span>*/}
                            {/*    </li>*/}
                            {/*<li className="space-x-2">*/}
                            {/*    <span className="text-red-500 font-semibold">&check;</span>*/}
                            {/*    <span>Second advantage weekly</span>*/}
                            {/*</li>*/}
                            {/*<li className="space-x-2">*/}
                            {/*    <span className="text-red-500 font-semibold">&check;</span>*/}
                            {/*    <span>Third advantage donate to project</span>*/}
                            {/*</li>*/}
                            {/*</ul>*/}
                            {/*<p className="flex items-center justify-center space-x-4 text-lg text-gray-600 text-center">*/}
                            {/*    <span>Call us at</span>*/}
                            {/*    <a href="tel:+24300" className="flex space-x-2 items-center text-red-600">*/}
                            {/*        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6"*/}
                            {/*             viewBox="0 0 16 16">*/}
                            {/*            <path*/}
                            {/*                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>*/}
                            {/*        </svg>*/}
                            {/*        <span className="font-semibold">+1 000 000</span>*/}
                            {/*    </a>*/}
                            {/*    <span>or</span>*/}
                            {/*</p>*/}
                            {/*<button type="submit" title="Submit"*/}
                            {/*        className="block w-full py-3 px-6 text-center rounded-xl transition bg-red-600 hover:bg-red-700 active:bg-red-800 focus:bg-indigo-600">*/}
                            {/*<span className="text-white font-semibold">*/}
                            {/*    Send us an email*/}
                            {/*</span>*/}
                            {/*</button>*/}
                        </div>
                    </div>


                    <div className="relative group md:w-6/12 lg:w-7/12">
                        <div aria-hidden="true"
                             className="absolute top-0 w-full h-full transition duration-500 group-hover:scale-105"></div>
                        <div className="text-left relative p-4 pt-16 md:p-8 md:pt-8 md:pl-16 md:rounded-r-2xl lg:pl-20 lg:p-16">
                            <h2 className="text-2xl md:text-3xl my-4">Transport</h2>
                            <p className="text-gray-700">
                                Prijs is exclusief transport. Fast Sspinning verzorgt in overleg ook het transport van de spinningfietsen naar de
                                gewenste locatie. Afhankelijk afstand naar de locatie en het aantal gewenst fietsen
                                kunnen we een gepaste offerte maken.
                                De fietsen worden dan geleverd tot aan de deur.
                                Het plaatsen van de fietsen is altijd eigen verantwoordelijkheid.
                            </p>

                            {/*<ul role="list" className="space-y-4 py-6 text-gray-600">*/}
                            {/*    <li className="space-x-2">*/}
                            {/*        /!*<span className="text-purple-500 font-semibold">&check;</span>*!/*/}
                            {/*        <span>First premium advantage</span>*/}
                            {/*    </li>*/}
                            {/*    <li className="space-x-2">*/}
                            {/*        /!*<span className="text-purple-500 font-semibold">&check;</span>*!/*/}
                            {/*        <span>Second advantage weekly</span>*/}
                            {/*    </li>*/}
                            {/*    <li className="space-x-2">*/}
                            {/*        /!*<span className="text-purple-500 font-semibold">&check;</span>*!/*/}
                            {/*        <span>Third advantage donate to project</span>*/}
                            {/*    </li>*/}
                            {/*    <li className="space-x-2">*/}
                            {/*        /!*<span className="text-purple-500 font-semibold">&check;</span>*!/*/}
                            {/*        <span>Fourth, access to all components weekly</span>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                            {/*<p className="text-gray-700">Team can be any size, and you can add or switch members as*/}
                            {/*    needed.*/}
                            {/*    Companies using our platform include:</p>*/}
                            {/*<div className="mt-6 flex justify-between gap-6">*/}
                            {/*    <img className="w-16 lg:w-24"*/}
                            {/*         src="https://tailus.io/sources/blocks/organization/preview/images/clients/airbnb.svg"*/}
                            {/*         loading="lazy" alt="airbnb"/>*/}
                            {/*    <img className="w-8 lg:w-16"*/}
                            {/*         src="https://tailus.io/sources/blocks/organization/preview/images/clients/bissell.svg"*/}
                            {/*         loading="lazy" alt="bissell"/>*/}
                            {/*    <img className="w-6 lg:w-12"*/}
                            {/*         src="https://tailus.io/sources/blocks/organization/preview/images/clients/ge.svg"*/}
                            {/*         loading="lazy" alt="ge"/>*/}
                            {/*    <img className="w-20 lg:w-28"*/}
                            {/*         src="https://tailus.io/sources/blocks/organization/preview/images/clients/microsoft.svg"*/}
                            {/*         loading="lazy" alt="microsoft"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>


            </section>
            <section className="mt-8 sm:mt-16 mb-24">
                <h2 className="text-2xl md:text-3xl my-4">Contact</h2>
                <p className="text-gray-700">
                    Voor meer informatie neem direct contact op:
                </p>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-8">
                    <Button href={'https://wa.me/31652052554'}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
                        </svg>
                        WhatsApp</Button>
                    <Button href={'mailto:info@fastsspinning.nl'}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>
                        Email</Button>
                    <Button href="tel:+31652052554">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>
                        Bel 06-52052554
                    </Button>
                </div>
            </section>
        </div>
    )
        ;
}
